/* eslint-disable camelcase */
import Tracking from './Tracking';

export default class GoogleTracking extends Tracking {
  public pushEvent(event: object): void {
    window.dataLayer.push(this.formatWithDynamicData(event));
  }

  public setProductPageTracking(): void {
    setTimeout(() => {
      this.pushEvent({
        event: 'view_item',
        ecommerce: this.formatWithCurrencies({
          currency: this.currency,
          items: [this.pageProductTrackingInformation],
        }),
      });
    }, 1000);
  }

  public getWishlistTracking(productTracking): object {
    return {
      event: 'add_to_wishlist',
      page_type: this.pageType,
      wishlist_name: 'wishlist',
      items: [productTracking],
    };
  }

  public getProductPageBsaodTracking(boutiqueId, boutiqueName): object {
    return {
      event: 'bsaod',
      boutique_id: boutiqueId,
      boutique_name: boutiqueName,
      items: [this.pageProductTrackingInformation],
    };
  }

  public getBackInStockTracking(): object {
    return {
      event: 'backInStock',
      items: [this.pageProductTrackingInformation],
    };
  }

  public getHeaderLinkTracking(link): object {
    return {
      event: 'header_link',
      link,
    };
  }

  public async getNewsletterSubscriptionTracking($email): Promise<object> {
    $email = await this.sha256($email);
    return {
      event: 'newsletter_subscription',
      user_email: $email,
    };
  }

  public getSearchResultsTracking(searchTerm, nbSearchResults): object {
    return {
      event: 'search',
      search_term: searchTerm,
      search_results: nbSearchResults,
    };
  }

  public getSearchResultsLinkTracking(searchTerm, nbSearchResults, searchClicked): object {
    return {
      event: 'view_search_results',
      search_term: searchTerm,
      search_results: nbSearchResults,
      search_clicked: searchClicked,
    };
  }

  public getStoreSearchTracking(searchTerm) {
    return {
      event: 'store_search',
      store_search_term: searchTerm,
    };
  }

  public async getContactFormTracking(contactMode, contactReason, userEmail): Promise<object> {
    userEmail = await this.sha256(userEmail);
    return {
      event: 'contact_requested',
      contact_mode: contactMode,
      contact_reason: contactReason,
      user_email: userEmail,
    };
  }

  public async getBoutiqueAppointFormTracking(appointmentId, boutiqueId, visitPurpose, userEmail): Promise<object> {
    userEmail = await this.sha256(userEmail);
    return {
      event: 'baa_confirmation',
      appointment_id: appointmentId,
      boutique_id: boutiqueId,
      page_country: this.pageCountry,
      visit_purpose: visitPurpose,
      page_language: this.pageLanguage,
      preferred_language: this.pageLanguage, // No selector of languages
      user_email: userEmail,
    };
  }

  public async getAccountCreationFormTracking(userEmail): Promise<object> {
    userEmail = await this.sha256(userEmail);
    return {
      event: 'sign_up',
      user_email: userEmail,
    };
  }

  public async getLoginFormTracking(userEmail): Promise<object> {
    userEmail = await this.sha256(userEmail);
    return {
      event: 'login',
      method: 'email',
      user_email: userEmail,
    };
  }

  public getGEPFormTracking(): object {
    return { event: 'guarantee_extension_sent' };
  }

  public getAffirmTracking(): object {
    // TODO: To check later as not easy to implement
    return {
      event: 'affirm_apply_now',
      ...this.pageProductTrackingInformation,
    };
  }

  public getCheckoutBoutiquePickUpTracking(boutiqueId, boutiqueName, items): object {
    return {
      event: 'boutique_pickup',
      boutique_id: boutiqueId,
      boutique_name: boutiqueName,
      items,
    };
  }

  public getSalesBarometerTracking(): object {
    return { event: 'sales_barometer' };
  }

  public getAddToCartTracking(): object {
    return {
      event: 'add_to_cart',
      ecommerce: this.formatWithCurrencies({
        currency: this.currency,
        items: [this.pageProductTrackingInformation],
      }),
    };
  }

  public getRemoveFromCartTracking(item): object {
    return {
      event: 'remove_from_cart',
      ecommerce: this.formatWithCurrencies({
        currency: this.currency,
        items: [item],
      }),
    };
  }

  public getViewCartTracking(items, currency, userStatus): object {
    return {
      event: 'view_cart',
      ecommerce: {
        currency,
        userstatus: userStatus,
        items,
      },
    };
  }

  public getCheckoutStep1Tracking(items, currency, userStatus): object {
    return {
      event: 'begin_checkout',
      ecommerce: {
        currency,
        userstatus: userStatus,
        items,
      },
    };
  }

  public getCheckoutStep2Tracking(items, currency, userStatus, shippingTier): object {
    return {
      event: 'add_shipping_info',
      ecommerce: {
        currency,
        userstatus: userStatus,
        shipping_tier: shippingTier,
        items,
      },
    };
  }

  public getCheckoutStep3Tracking(items, currency, userStatus, shippingTier): object {
    return {
      event: 'add_billing_info',
      ecommerce: {
        currency,
        userstatus: userStatus,
        shipping_tier: shippingTier,
        items,
      },
    };
  }

  public getCheckoutStep4Tracking(items, currency, userStatus, paymentType): object {
    return {
      event: 'add_payment_info',
      ecommerce: {
        currency,
        userstatus: userStatus,
        payment_type: paymentType,
        items,
      },
    };
  }

  public getProductListClickTracking(item): object {
    return {
      event: 'select_item',
      ecommerce: this.formatWithCurrencies({
        currency: this.currency,
        items: [item],
      }),
    };
  }

  public getLightEcomGiftWrappingTracking(items, shippingTier): object {
    return {
      event: 'order_online_form_card',
      ecommerce: this.formatWithCurrencies({
        currency: this.currency,
        userstatus: 'guest',
        shipping_tier: shippingTier,
        items,
      }),
    };
  }

  public getLightEcomGiftMessageTracking(items, shippingTier): object {
    return {
      event: 'order_online_form_message',
      ecommerce: this.formatWithCurrencies({
        currency: this.currency,
        userstatus: 'guest',
        shipping_tier: shippingTier,
        items,
      }),
    };
  }

  public getLightEcomHomeDeliveryCtaTracking(items, shippingTier): object {
    return {
      event: 'order_online_form_home_delivery',
      ecommerce: this.formatWithCurrencies({
        currency: this.currency,
        userstatus: 'guest',
        shipping_tier: shippingTier,
        items,
      }),
    };
  }

  public getLightEcomBoutiqueDeliveryCtaTracking(items, shippingTier): object {
    return {
      event: 'order_online_form_boutique_pickup',
      ecommerce: this.formatWithCurrencies({
        currency: this.currency,
        userstatus: 'guest',
        shipping_tier: shippingTier,
        items,
      }),
    };
  }

  public getLightEcomHomeDeliveryFormTracking(items, shippingTier): object {
    return {
      event: 'all_order_online_form_address_validated',
      ecommerce: this.formatWithCurrencies({
        currency: this.currency,
        userstatus: 'guest',
        shipping_tier: shippingTier,
        items,
      }),
    };
  }

  public getLightEcomBoutiqueDeliveryFormTracking(items, shippingTier): object {
    return {
      event: 'order_online_form_boutique_pickup',
      ecommerce: this.formatWithCurrencies({
        currency: this.currency,
        userstatus: 'guest',
        shipping_tier: shippingTier,
        items,
      }),
    };
  }

  public getLightEcomSameBillingTracking(items, shippingTier): object {
    return {
      event: 'order_online_form_same_billing',
      ecommerce: this.formatWithCurrencies({
        currency: this.currency,
        userstatus: 'guest',
        shipping_tier: shippingTier,
        items,
      }),
    };
  }

  public getLightEcomBillingFormTracking(items, shippingTier): object {
    return {
      event: 'order_online_form_other_billing',
      ecommerce: this.formatWithCurrencies({
        currency: this.currency,
        userstatus: 'guest',
        shipping_tier: shippingTier,
        items,
      }),
    };
  }

  public getLightEcomSubmitTracking(items, shippingTier): object {
    return {
      event: 'order_online_form_submitted',
      ecommerce: this.formatWithCurrencies({
        currency: this.currency,
        userstatus: 'guest',
        shipping_tier: shippingTier,
        items,
      }),
    };
  }

  public getFilterTracking(filter, subFilter): object {
    return {
      event: 'filter_tracking',
      filter,
      sub_filter: subFilter,
    };
  }

  public getProductListDetailsTrackingEvents(items: object[], listName: string, listId?: string): object[] {
    return [
      {
        event: 'view_item_list',
        ecommerce: this.formatWithCurrencies({
          currency: this.currency,
          items: items.map((item) => ({
            ...item,
            item_list_name: listName,
            item_list_id: listId,
          })),
        }),
      },
    ];
  }

  public getClickedCtaTracking(parameters: object): object {
    return {
      event: 'clicked_cta',
      ...parameters,
    };
  }

  protected bindEventListeners(): void {
    document.addEventListener('videostart', (e: CustomEvent) => this.pushVideoInteraction(e));
    document.addEventListener('videoprogress', (e: CustomEvent) => this.pushVideoInteraction(e));
    document.addEventListener('videoend', (e: CustomEvent) => this.pushVideoInteraction(e));
    document.addEventListener('video_gallery_filter', (e: CustomEvent) => this.pushVideoGalleryFilterInteraction(e));
    document.addEventListener('item_simulator', (e: CustomEvent) => this.pushSimulatorInteraction(e));
    document.addEventListener('360_item_view', (e: CustomEvent) => this.push360Interaction(e));
  }

  protected pushVideoInteraction(event: CustomEvent): void {
    if (!this.shouldPushVideoInteractionEvent(event)) {
      return;
    }

    const trackingData = {
      videostart: { event: 'video_start', video_title: event.detail.videoTracking.title },
      videoend: { event: 'video_complete', video_title: event.detail.videoTracking.title },
      videoprogress: {
        event: 'video_progress',
        video_title: event.detail.videoTracking.title,
        video_percent: event.detail.progress,
      },
    }[event.type];

    this.pushEvent(trackingData);
  }

  protected shouldPushVideoInteractionEvent(event: CustomEvent): boolean {
    const forceTracking = event.detail.videoTracking.force_tracking || false;
    const skipVideoprogress = event.detail.videoTracking.skip_videoprogress || false;

    return forceTracking || !skipVideoprogress;
  }

  protected pushVideoGalleryFilterInteraction(event: CustomEvent): void {
    this.pushEvent({
      event: event.type,
      filter: event.detail.filter,
    });
  }

  protected pushSimulatorInteraction(event: CustomEvent): void {
    this.pushEvent({
      event: event.type,
      interaction_type: event.detail.interactionType,
      items: [event.detail.videoTracking.item],
    });
  }

  protected push360Interaction(event: CustomEvent): void {
    this.pushEvent({
      event: event.type,
      items: [event.detail.videoTracking.item],
    });
  }

  public getPasswordForgotTracking(): object {
    return {
      event: 'password_forgot',
    };
  }

  public getSearchFilterViewResultsTracking(term = '', filter = ''): object {
    return {
      event: 'search_filter',
      term,
      filter,
    };
  }
}
