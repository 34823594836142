/* eslint-disable camelcase */
import Tracking from './Tracking';
import { reduce, map, merge } from 'lodash-es';

export default class SensorsDataTracking extends Tracking {
  sensors: object;

  public constructor() {
    super();

    this.sensors = window.sensors;
  }

  public pushEvent(event): void {
    if (!this.hasUserAcceptedCookieConsent()) {
      return;
    }

    const eventName = event.event;
    delete event.event;

    // eslint-disable-next-line
    console.log('track', eventName, event);
    this.sensors.track(eventName, event);
  }

  protected itemsToLists(items: object[]): object {
    const data = merge(
      { quantity: items.length },
      reduce(
        items.length > 0 ? Object.keys(items[0]) : [],
        (result, key) => {
          result[`${key}list`] = map(items, key);
          return result;
        },
        {}
      )
    );
    delete data.currencycodelist;
    return data;
  }

  public setProductPageTracking(): void {
    setTimeout(() => {
      this.pushEvent({
        event: 'ProductDetailView',
        currency: this.currency,
        ...this.pageProductTrackingInformation,
      });
    }, 1000);
  }

  public getWishlistTracking(productTracking): object {
    return {
      event: 'AddToWishlist',
      actionlocation: this.pageType,
      wishlistname: 'wishlist',
      ...productTracking,
    };
  }

  public getProductPageBsaodTracking(boutiqueId, boutiqueName): object {
    return {
      event: 'BoutiqueStockAvailability',
      boutiqueid: boutiqueId,
      boutiquename: boutiqueName,
      ...this.pageProductTrackingInformation,
    };
  }

  public getBackInStockTracking(): object {
    return {
      event: 'BackinStock',
      ...this.pageProductTrackingInformation,
    };
  }

  public getHeaderLinkTracking(link): object {
    return {
      event: 'HeaderLink',
      link,
    };
  }

  public async getNewsletterSubscriptionTracking(): Promise<object> {
    return {
      event: 'NewsletterSubscription',
    };
  }

  public getSearchResultsTracking(searchTerm, nbSearchResults): object {
    return {
      event: 'InternalSearch',
      searchterm: searchTerm,
      searchresults: nbSearchResults,
    };
  }

  public getSearchResultsLinkTracking(searchTerm, nbSearchResults, searchClicked): object {
    return {
      event: 'SearchResultSelected',
      searchterm: searchTerm,
      searchresults: nbSearchResults,
      searchclicked: searchClicked,
    };
  }

  public getStoreSearchTracking(searchTerm): object {
    return {
      event: 'StoreBoutiqueSearch',
      storesearchterm: searchTerm,
    };
  }

  // eslint-disable-next-line no-unused-vars
  public async getContactFormTracking(contactMode, contactReason): Promise<object> {
    return {
      event: 'ContactRequest',
    };
  }

  public async getBoutiqueAppointFormTracking(appointmentId, boutiqueId, visitPurpose): Promise<object> {
    return {
      event: 'BookAnAppointment',
      appointmentid: appointmentId,
      boutiqueid: boutiqueId,
      countryisocode: this.pageCountry,
      visitpurpose: visitPurpose,
      languageisocode: this.pageLanguage,
      language: this.pageLanguage, // No selector of languages
    };
  }

  public async getAccountCreationFormTracking(): Promise<object> {
    return { event: 'AccountCreation' };
  }

  public async getLoginFormTracking(): Promise<object> {
    return {
      event: 'Login',
      loginType: 'email',
    };
  }

  public getGEPFormTracking(): object {
    return { event: 'GuarenteeExtensionSent' };
  }

  public getAffirmTracking(): object {
    // TODO: To check later as not easy to implement
    return {
      event: 'AffirmApplyNow',
      ...this.pageProductTrackingInformation,
    };
  }

  public getCheckoutBoutiquePickUpTracking(boutiqueId, boutiqueName, items): object {
    const item = items.length > 0 ? items[0] : {};

    return {
      event: 'BoutiquePickUp',
      boutiqueid: boutiqueId,
      boutiquename: boutiqueName,
      ...item,
    };
  }

  public getSalesBarometerTracking(): object {
    return { event: 'SalesBarometer' };
  }

  public getAddToCartTracking(): object {
    return {
      event: 'AddToCart',
      currencycode: this.currency,
      ...this.pageProductTrackingInformation,
      quantity: 1,
    };
  }

  public getRemoveFromCartTracking(item): object {
    return {
      event: 'RemoveFromCart',
      currencycode: this.currency,
      ...item,
      quantity: 1,
    };
  }

  public getViewCartTracking(items, currency): object {
    return {
      event: 'ViewCart',
      currencycode: currency,
      ...this.itemsToLists(items),
    };
  }

  public getCheckoutStep1Tracking(items, currency): object {
    return {
      event: 'BeginCheckout',
      currencycode: currency,
      ...this.itemsToLists(items),
    };
  }

  public getCheckoutStep2Tracking(items, currency): object {
    return {
      event: 'AddShippingInfo',
      currencycode: currency,
      ...this.itemsToLists(items),
    };
  }

  public getCheckoutStep3Tracking(items, currency): object {
    return {
      event: 'AddBillingInfo',
      currencycode: currency,
      ...this.itemsToLists(items),
    };
  }

  public getCheckoutStep4Tracking(items, currency): object {
    return {
      event: 'AddPaymentInfo',
      currencycode: currency,
      ...this.itemsToLists(items),
    };
  }

  public getProductListClickTracking(item): object {
    return {
      event: 'ProductListClick',
      currencycode: this.currency,
      ...item,
    };
  }

  public getLightEcomGiftWrappingTracking(items, shippingTier): object {
    return {
      event: 'OrderOnlineFormCard',
      currencycode: this.currency,
      userstatus: 'guest',
      shippingtier: shippingTier,
      ...this.itemsToLists(items),
    };
  }

  public getLightEcomGiftMessageTracking(items, shippingTier): object {
    return {
      event: 'OrderOnlineFormMessage',
      currencycode: this.currency,
      userstatus: 'guest',
      shippingtier: shippingTier,
      ...this.itemsToLists(items),
    };
  }

  public getLightEcomHomeDeliveryCtaTracking(items, shippingTier): object {
    return {
      event: 'OrderOnlineFormHomeDelivery',
      currencycode: this.currency,
      userstatus: 'guest',
      shippingtier: shippingTier,
      ...this.itemsToLists(items),
    };
  }

  public getLightEcomBoutiqueDeliveryCtaTracking(items, shippingTier): object {
    return {
      event: 'OrderOnlineFormButiquPickUp',
      currencycode: this.currency,
      userstatus: 'guest',
      shippingtier: shippingTier,
      ...this.itemsToLists(items),
    };
  }

  public getLightEcomHomeDeliveryFormTracking(items, shippingTier): object {
    return {
      event: 'OrderOnlineFormAddressValiated',
      currencycode: this.currency,
      userstatus: 'guest',
      shippingtier: shippingTier,
      ...this.itemsToLists(items),
    };
  }

  public getLightEcomBoutiqueDeliveryFormTracking(items, shippingTier): object {
    return {
      event: 'OrderOnlineFormBoutiquePickUpValidated',
      currencycode: this.currency,
      userstatus: 'guest',
      shippingtier: shippingTier,
      ...this.itemsToLists(items),
    };
  }

  public getLightEcomSameBillingTracking(items, shippingTier): object {
    return {
      event: 'OrderOnlineFormSameBilling',
      currencycode: this.currency,
      userstatus: 'guest',
      shippingtier: shippingTier,
      ...this.itemsToLists(items),
    };
  }

  public getLightEcomBillingFormTracking(items, shippingTier): object {
    return {
      event: 'OrderOnlineFormOtherBilling',
      currencycode: this.currency,
      userstatus: 'guest',
      shippingtier: shippingTier,
      ...this.itemsToLists(items),
    };
  }

  public getLightEcomSubmitTracking(items, shippingTier): object {
    return {
      event: 'OrderOnlineFormSubmitted',
      currencycode: this.currency,
      userstatus: 'guest',
      shippingtier: shippingTier,
      ...this.itemsToLists(items),
    };
  }

  public getFilterTracking(filter, subFilter): object {
    return {
      event: 'FiltersTracking',
      filter,
      subfilter: subFilter,
    };
  }

  public addCheckoutStepEvent(step, tracking): void {
    // See DSPHX-1194, we want to push the event all the time for Sensors
    this.pushEvent(tracking);
  }

  public getStartingEngravingTracking($cta): object {
    return {
      event: 'strating_engraving',
      engraving_cta: $cta,
    };
  }

  public getEngravingSearchReferenceTracking(): object {
    return {
      event: 'engraving_search_reference',
    };
  }

  public getEngravingFindReferenceTracking(): object {
    return {
      event: 'engraving_find_reference',
    };
  }

  public getEngravingSelectReferenceTracking($itemId, $itemName): object {
    return {
      event: 'engraving_search_reference',
      item_id: $itemId,
      item_name: $itemName,
    };
  }

  public getProductListDetailsTrackingEvents(items: any[], listName: string, listId?: string): object[] {
    return [
      ...items.map(({ index, ...data }) => ({
        ...data,
        event: 'ProductListViewDetail',
        listname: listName,
        productidlist: listId,
      })),
    ];
  }

  public getProductListTracking(listName: string, listId?: string): object {
    return {
      event: 'ProductListView',
      listname: listName,
      productidlist: listId,
    };
  }

  protected bindEventListeners(): void {
    document.addEventListener('videostart', (e: CustomEvent) => this.pushVideoInteraction(e));
  }

  protected pushVideoInteraction(event: CustomEvent): void {
    if (!this.shouldPushVideoInteractionEvent(event)) {
      return;
    }

    this.pushEvent({
      event: 'VideoProgress',
      videostatus: event.detail.progress,
      videoname: event.detail.videoTracking.title,
    });
  }

  protected shouldPushVideoInteractionEvent(event: CustomEvent): boolean {
    /**
     * For SensorsData, we only push the 'videoprogress' event.
     * If the video is autoplayed, we do not push the event.
     * Cf: https://agile.richemont.com/confluence/pages/viewpage.action?pageId=174687393
     */
    const video = (event.target as HTMLElement).getElementsByTagName('video')[0];
    const forceTracking = event.detail.videoTracking.force_tracking || false;
    const skipVideoprogress = event.detail.videoTracking.skip_videoprogress || false;
    const isAutoplay = video?.hasAttribute('autoplay') || false;

    return forceTracking || (!skipVideoprogress && !isAutoplay);
  }

  public getPasswordForgotTracking(): object {
    return {};
  }

  public getSearchFilterViewResultsTracking(term = '', filter = ''): object {
    return {
      event: 'FilterSearch',
      term,
      filter,
    };
  }
}
